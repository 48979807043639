import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Wishlist} from "../models";
import {GenericHttpService} from "../../shared/services/generic-http.service";

@Injectable({
  providedIn: 'root'
})
export class WishlistService extends GenericHttpService<Wishlist, Wishlist> {
  private _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super(httpClient,
      'wishlists');
    this._httpClient = httpClient;
  }

  share(wishlistKey: string, groupKey: string) {
    return this._httpClient.post(`${this.endpointUrl}/${wishlistKey}/share/group/${groupKey}`, {wishlistKey: wishlistKey, groupKey: groupKey});
  }
}
