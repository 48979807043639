import { Component, OnInit } from '@angular/core';
import {Group} from "../../models";
import {NotificationService, GroupService} from "../../services";
import {ClipboardService} from "ngx-clipboard";
import {Globals} from "../../../shared/models";

@Component({
  selector: 'app-my-groups',
  templateUrl: './my-groups.component.html',
  styleUrls: ['./my-groups.component.scss']
})
export class MyGroupsComponent implements OnInit {
  groups: Group[] = [];

  constructor(private groupService: GroupService,
              private clipboardService: ClipboardService,
              private notificationService: NotificationService,
              private globals: Globals) {
    console.log('My groups here')
  }

  copyLink(group: Group): void{
    try {
      this.clipboardService.copyFromContent(this.globals.baseHref + '/groups/' + group.groupKey);
      this.notificationService.success('Link copied to clipboard.');
    }
    finally {

    }
  }

  ngOnInit() {
    this.groupService.query().subscribe((groups) => {
      if (groups) {
        this.groups = groups;
      }
    });
  }
}
