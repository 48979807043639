import { Injectable } from '@angular/core';
import {GenericHttpService} from "../../shared/services/generic-http.service";
import {WishlistGroup} from "../models";
import {HttpClient} from "@angular/common/http";
import {group} from "@angular/animations";

@Injectable({
  providedIn: 'root'
})
export class WishlistGroupService extends GenericHttpService<WishlistGroup, WishlistGroup> {
  private _httpClient: HttpClient;
  constructor(httpClient: HttpClient) {
    super(httpClient,
      'wishlistgroups');
    this._httpClient = httpClient
  }

  public unassign(groupKey: string, wishlistKey: string) {
    return this._httpClient.delete(`${this.endpointUrl}/${groupKey}/unassign/${wishlistKey}`)
  }
}
