import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Observable} from "rxjs";
import {Wishlist} from "../../models";
import {WishlistService} from "../../services";

@Injectable()
export class WishlistResolver  {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Wishlist> | Promise<Wishlist> | Wishlist {
    return this.wishlistService.get(route.paramMap.get('id'));
  }

  constructor(private wishlistService: WishlistService) { }
}
