import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidatorFn} from "@angular/forms";

@Directive({
  selector: '[sameAs][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SameAsDirective,
      multi: true
    }
  ]
})
export class SameAsDirective {

  @Input('sameAs') sameAs: any;

  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.sameAs.value !== control.value ? { 'sameAs': true } : null
  }
}
