import {Component} from '@angular/core';
import {AuthenticationService} from '../services';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {
  showSpinner: boolean = false;
  myModel: string;

  constructor(private authenticationService: AuthenticationService) {
  }

  login() {
    this.authenticationService.login();
  }
}
