import { environment } from '../../environments/environment';
import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { AuthGuard } from '../guards/auth.guard';
import { WishlistRoutes } from '../routing/wishlist-route';
import { AuthStorage } from './auth-storage';
const wishlistRoutes = new WishlistRoutes();
const secureRoutes = wishlistRoutes.routes
  .map((r) => r.route)
  .filter((route) => route.canActivate?.filter((ca) => typeof ca === typeof AuthGuard))
  .map((route) => `${environment.apiUrl}/${route.path}`);
console.log(secureRoutes);
@NgModule({
  imports: [AuthModule.forRoot({
    config: {
      authority: environment.authUrl,
      redirectUrl: window.location.origin,
      silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
      unauthorizedRoute: window.location.origin,
      forbiddenRoute: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      clientId: 'web',
      scope: 'openid web_api', // 'openid profile offline_access ' + your scopes
      responseType: 'id_token token',
      silentRenew: true,
      renewTimeBeforeTokenExpiresInSeconds: environment.authTokenNotificationTimeInMinutes * 60,
      secureRoutes: [`${environment.apiUrl}/`],
    }
  })],
  exports: [AuthModule]
})
export class AuthConfigModule {
}
