import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericHttpService } from '../../shared/services/generic-http.service';
import { UserProfileModel } from '../models';

@Injectable()
export class UserProfileService extends GenericHttpService<UserProfileModel, UserProfileModel> {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'userprofiles');
  }
}
