import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { EnvironmentService } from '../../environments/environment.service';
import {Globals} from '../models';
import {Injectable} from '@angular/core';

interface IGenericHttpService {
  delete (id: string | number): Observable<any>;
  get<TOutput>(id?: string | number): Observable<TOutput>;
  post<TInput, TOutput>(data: TInput): Observable<TOutput>;
  put<TInput, TOutput>(data: TInput): Observable<TOutput>;
}

// @Injectable()
export class GenericHttpService<TInput, TOutput> implements IGenericHttpService {
  private globals = new Globals();
  private environmentService = new EnvironmentService();
  constructor(private httpClient: HttpClient,
              private endpoint: string) {
  }

  /**
   * Returns the API url from the globals model. No setter.
   */
  public get apiUrl(): string {
    return this.environmentService.apiUrl;
  }

  /**
   * Returns the fully qualified endpoint URL.
   */
  public get endpointUrl(): string {
    return `${this.environmentService.apiUrl}/${this.endpoint}`;
  }

  /**
   * Returns the fully qualified auth URL.
   */
  public get authUrl(): string {
    return `${this.environmentService.authUrl}/${this.endpoint}`;
  }

  private getQueryString(queryOptions: object) {
    return Object
      .keys(queryOptions)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryOptions[key])}`)
      .join('&');
  }

  /**
   * HTTPGET
   *
   * Queries the server to retrieve an array of one or more entities of type TInput.
   *
   * If {@param queryOptions} is passed as an {@link Object}, it will send them to the server as a query string.
   *
   * @param queryOptions (optional) The query parameters to include in the request.
   * @returns Returns an {@link Observable} containing an array of entities of type TInput.
   */
  public query(queryOptions?: object): Observable<TInput[]> {
    let url = `${this.apiUrl}/${this.endpoint}`;
    if (queryOptions) {
      url += '/query?' + this.getQueryString(queryOptions);
    }
    return this.httpClient
      .get<TInput[]>(url);
  }

  /**
   * HTTPGET
   *
   * Gets a single entity of the specified type. If an `id` is passed, it will include it in the request.
   *
   * @param id (optional) Id of the entity to get.
   * @returns Returns an {@link Observable} containing single entity of type TInput.
   */
  public get<TOutput>(id?: string | number): Observable<TOutput> {
    if (id) {
      return this.httpClient
        .get<TOutput>(`${this.apiUrl}/${this.endpoint}/${id}`);
    } else {
      return this.httpClient
        .get<TOutput>(`${this.apiUrl}/${this.endpoint}`);
    }
  }

  /**
   * HTTPPOST
   *
   * Creates a new entity of type TInput. Alias for the `post(item: TInput)` method.
   *
   * @param item The entity to post to the server and create.
   * @returns Returns an {@link Observable} containing the new entity of type TInput.
   */
  public create(item: TInput): Observable<TInput> {
    return this.post(item);
  }

  /**
   * HTTPDELETE
   *
   * Deletes an entity with the specified Id.
   * @param id Id of the entity to DELETE.
   * @returns Returns an empty {@link Observable}.
   */
  public delete(id: string | number) {
    return this.httpClient
      .delete(`${this.apiUrl}/${this.endpoint}/${id}`);
  }

  /**
   * HTTPPOST
   *
   * Sends an object of type TInput to the server.
   *
   * @param item The item to POST to the server and create.
   * @returns Returns an {@link Observable} containing the new entity of type TInput.
   */
  public post<TInput, TOutput>(item: TInput): Observable<TOutput> {
    return this.httpClient
      .post<TOutput>(`${this.apiUrl}/${this.endpoint}`, item);
  }

  /**
   * HTTPPUT
   *
   * Sends an object of type TInput to the server that will be updated on the backend.
   *
   * @param item The item to PUT to the server.
   * @returns Returns an {@link Observable} containing the updated entity of type TInput.
   */
  public put<TInput, TOutput>(item: TInput): Observable<TOutput> {
    return this.httpClient
      .put<TOutput>(`${this.apiUrl}/${this.endpoint}`, item);
  }
}
