
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import {Observable} from "rxjs";

@Directive({
  selector: '[wlClickAndLoad]'
})
export class ClickAndLoadDirective {

  constructor(private element: ElementRef) { }

  @Input() appClickAndLoad: any;

  @HostListener('click')
  onClick() {
    console.log(this.element);
    console.log(this.appClickAndLoad);
    this.element.nativeElement.setAttribute('disabled', true);
    this.appClickAndLoad().subcribe(() => this.element.nativeElement.setAttribute('disabled', false));
  }
}
