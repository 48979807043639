import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GenericHttpService} from "./services/generic-http.service";
import {Globals} from "./models";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    //GenericHttpService,
    Globals
  ]
})
export class SharedModule { }
