import { Directive } from '@angular/core';

@Directive({
  selector: '[appSpinnerOnDisable]'
})
export class SpinnerOnDisableDirective {

  constructor() { }

}
