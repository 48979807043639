import {Member, PublicityLevel, Wishlist} from "./"

export class Group {
  id: string;
  name: string;
  description: string;
  groupKey: string;
  publicityLevel: PublicityLevel; // Currently unused, may be used in the future
  wishlists: Wishlist[];
  members: Member[] = [];
  memberCount: number = this.members ? this.members.length : 0;
}
