import {Injectable} from '@angular/core';
import {Group, Member} from "../models";
import {GenericHttpService} from "../../shared/services/generic-http.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class GroupService extends GenericHttpService<Group, Group> {
  private _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super(httpClient,
      'groups');
    this._httpClient = httpClient;
  }

  invite(id: string, members: Member[]) {
    let memberIds: string[] = members.map((m) => {
      return m.id;
    });
    return this._httpClient.post(`${this.endpointUrl}/${id}/invite`, memberIds);
  }
}
