import {Component, OnInit} from '@angular/core';
import {PublicityLevel, Wishlist} from "../../models";
import {finalize} from "rxjs/operators";
import {Router} from "@angular/router";
import {WishlistService} from "../../services";

@Component({
  selector: 'app-add-wishlist',
  templateUrl: './add-wishlist.component.html',
  styleUrls: ['./add-wishlist.component.scss']
})
export class AddWishlistComponent implements OnInit {
  wishlist: Wishlist = new Wishlist();
  creatingWishlist: boolean;

  constructor(private wishlistService: WishlistService, private router: Router) {
  }

  createWishlist(): void {
    this.wishlist.publicityLevel = new PublicityLevel();
    this.wishlist.publicityLevel.id = 1;
    this.creatingWishlist = true;
    this.wishlistService.create(this.wishlist)
      .pipe(finalize(() => {
        this.creatingWishlist = false;
      }))
      .subscribe((resp) => {
        this.router.navigateByUrl('/wishlists/' + resp.wishlistKey);
      });
  }

  ngOnInit() {
  }

}
