import { Component, OnInit } from '@angular/core';
import { NewItem, NewItemArticle } from '../../models';

@Component({
  selector: 'wl-whats-new-container',
  templateUrl: './whats-new-container.component.html',
  styleUrls: ['./whats-new-container.component.css']
})
export class WhatsNewContainerComponent implements OnInit {
  newUpdates: NewItemArticle[] = [
    {
      date: '21st March 2021',
      items: [
        new NewItem(`We are live!`)
      ]
    },
    {
      date: '18th March 2021',
      items: [
        new NewItem(`You can now create a bio in your profile by using the profile link in the top right-hand corner`)
      ]
    },
    {
      date: '15th March 2021',
      items: [
        new NewItem(`IMPORTANT: Your username no longer exists. Please login using your email address.`),
        new NewItem(`We now only show authenticated menu items if the user is logged in`),
        new NewItem(`Fixed a bug with navigation buttons going black on click`),
        new NewItem(`Improved the mobile menu UI`),
        new NewItem(`New, revamped designs!`),
        new NewItem(`Snow is over...`)
      ]
    },
    {
      date: '6th December 2020',
      items: [
        new NewItem(`Who says we never get snow?!?! ❄🌨☃`)
      ]
    },
    {
      date: '24th September 2020',
      items: [
        new NewItem(`Dialogs now show above dropdowns, fixing a bug with sharing where the dropdown would show over teh share dialog`)
      ]
    },
    {
      date: '18th September 2020',
      items: [
        new NewItem(`You can now see who's bought items on someone's wishlist! Their name will now appear below the checkbox. Remember that you can't see this if it's your own wishlist...`),
        new NewItem(`Links are now truncated to save space on the Wishlist page`),
        new NewItem(`The wishlist page has been overhauled`),
        new NewItem(`Item links are truncated by default. If you need to see the full link, click on the 'Show full link' button below it`),
        new NewItem(`The "What's New? list is slightly more interesting"`)
      ]
    },
    {
      date: '17th September 2020',
      items: [
        new NewItem(`Improved the styling of the website theme`),
        new NewItem(`Forgotten password works again (unless you use Microsoft services - pending tech update)`)
      ]
    },
    {
      date: '10th October 2019',
      items: [
        new NewItem(`Notifications are now more prominent and themed`),
        new NewItem(`You can now delete wishlists from the <a href="/wishlists">My Wishlists</a> page`),
        new NewItem(`Links are now themed using the website blue`),
        new NewItem(`You can now copy the link of a wishlist using the "share" button`)
      ]
    },
    {
      date: '4th November 2018',
      items: [
        new NewItem(`The design of the group pages has been completely reworked, <a href="/groups">check it out!</a>`),
        new NewItem(`You can now remove your Wishlists from a group via the delete button`)
      ]
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
