import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../services/account-service.service';
import {ForgottenPasswordViewModel} from './forgotten-password-view.model';
import {Subscription} from 'rxjs';
import {finalize, map} from 'rxjs/operators';
import {AuthenticationService, NotificationService} from '../../services';

@Component({
  selector: 'wl-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {
  tokenValid: boolean;
  previewPassword: boolean;
  resettingPassword: boolean;
  forgottenPasswordViewModel: ForgottenPasswordViewModel;
  subscription: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              private authService: AuthenticationService,
              private notificationService: NotificationService) {
  }

  togglePasswordDisplay(): void {
    this.previewPassword = !this.previewPassword;
  }

  resetPassword(): void {
    this.resettingPassword = true;
    this.subscription = this.accountService.resetPassword(this.forgottenPasswordViewModel).subscribe(((resp) => {
          this.notificationService.success('Your password has been updated. Please login with your new password.');
          console.log(resp);
          this.authService.login();
        }));
  }

  controlInvalid(control: any): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  ngOnInit() {
    this.tokenValid = this.route.snapshot.data.tokenValid;
    this.forgottenPasswordViewModel = new ForgottenPasswordViewModel(this.route.snapshot.paramMap.get('id'),
        this.route.snapshot.paramMap.get('token'));
  }

}
