import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParamGuard  {
  constructor(private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let pass: boolean = true;
    for (let p in next.params) {
      if (next.params[p] == '') {
        this.router.navigateByUrl(next.parent.routeConfig.path);
      }
    }

    return pass;
  }
}
