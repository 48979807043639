import { Component, Input, OnInit } from '@angular/core';
import { RegexUtil } from '../../utilities';

@Component({
  selector: 'wl-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.css']
})
export class PasswordStrengthComponent implements OnInit {
  @Input() passwordField: string;

  constructor() { }

  ngOnInit(): void {
  }

  passwordHasUpperCase(password: string): boolean {
    return !!password && password.toLowerCase() !== password;
  }

  passwordHasSymbol(password: string): boolean {
    return !!password && RegexUtil.password.matches(password);
  }

  passwordHasNumber(password: string): boolean {
    return !!password && /[0-9]/.test(password);
  }
}
