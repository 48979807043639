import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TemplateConditionalComplexityRule } from 'codelyzer';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private router: Router,
              private authService: AuthenticationService) {
  }

  canActivate(next: ActivatedRouteSnapshot) {
    return this.authService.isAuthenticated$.pipe(
      map(({isAuthenticated}) => {
        console.log('[AuthGuard] :: isAuthenticated', isAuthenticated)
        if (isAuthenticated) return true;

        /* TODO: Make this work with clicking links in the site. Currently only working off pasted links.*/
        let extras = {queryParams: {redirect: encodeURIComponent(location.pathname)}};
        this.authService.login();
        return false;
      })
    )
  }
}
