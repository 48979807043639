import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';

@Component({
  selector: 'wl-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss']
})
export class ProgressButtonComponent implements AfterViewInit {
  @Output() btnClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @Input('class') btnClass: string;
  @Input() showSpinner: boolean;
  @Input() disabled: boolean;

  @HostListener('click', ['$event'])
  public onClick(event:MouseEvent) {
    if (!this.disabled && !this.showSpinner)
      this.btnClicked.emit(event);
  }

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {

    this.renderer.removeAttribute(this.elementRef.nativeElement, 'class');
  }
}
