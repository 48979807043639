import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Group, Member, Wishlist} from "../../models";
import {GroupService, MemberService, NotificationService, WishlistGroupService, WishlistService} from "../../services";
import {debounceTime} from "rxjs/operators";
import {Subscription} from "rxjs";
import {ShareType} from "../../enums";
import {Md5} from "ts-md5";

declare var UIkit: any;

@Component({
  selector: 'app-view-edit-group',
  templateUrl: './view-edit-group.component.html',
  styleUrls: ['./view-edit-group.component.scss']
})
export class ViewEditGroupComponent implements OnInit {
  group: Group;
  inviteMemberModal: any;
  searchMemberList: Member[];
  memberSearchTerm: string;
  shareType: ShareType;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private memberService: MemberService,
              private groupService: GroupService,
              private notificationService: NotificationService,
              private wishlistGroupService: WishlistGroupService
  ) {
  }

  showInviteMemberDialog(): void {
    this.inviteMemberModal.show();
  }

  queryMembers(value: string): Subscription {
    return this.memberService.queryForGroup(value, this.group.groupKey)
      .subscribe((members) => {
        console.log(members);
        return this.searchMemberList = members;
      });
  }

  addMemberToInviteList(member: Member) {
    let memberArray: Member[] = [member];
    this.groupService.invite(this.group.groupKey, memberArray).subscribe((resp) => {
      this.notificationService.success(`${member.fullName} has been invited to ${this.group.name}.`);
      this.group.members.push(member);
      this.memberSearchTerm = '';
      this.searchMemberList = [];
    });
  }

  ngOnInit() {
    this.group = this.route.snapshot.data.group;
  }

  ngAfterViewInit() {
    this.inviteMemberModal = UIkit.modal('#invite-member-modal');
  }

  nameToHex(name: string) {
    const md5Name = Md5.hashStr(name).toString();
    return `#${md5Name.substr(0,6)}`;
  }

  onUnassignWishlistFromGroup(wishlist: Wishlist) {
    this.group.wishlists.splice(this.group.wishlists.indexOf(wishlist), 1);
    this.notificationService.success(`The wishlist '${wishlist.name}' has been unassigned from the group.`)
  }
}
