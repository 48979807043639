import { Injectable } from '@angular/core';
import { Route, CanActivateFn } from '@angular/router';
import { UserProfileContainerComponent } from '../components/user-profile-container/user-profile-container.component';
import { ViewUserProfileContainerComponent } from '../components/view-user-profile-container/view-user-profile-container.component';
import { WhatsNewContainerComponent } from '../whats-new/whats-new-container/whats-new-container.component';
import { MyWishlistsComponent } from '../wishlists/my-wishlists/my-wishlists.component';
import { HomeComponent } from '../home/home.component';
import { MyGroupsComponent } from '../groups/my-groups/my-groups.component';
import { AddWishlistComponent } from '../wishlists/add-wishlist/add-wishlist.component';
import { AddGroupComponent } from '../groups/add-group/add-group.component';
import { ViewEditWishlistComponent } from '../wishlists/view-edit-wishlist/view-edit-wishlist.component';
import { NotFoundComponent } from '../error/not-found/not-found.component';
import { ViewEditGroupComponent } from '../groups/view-edit-group/view-edit-group.component';
import { LoginRegisterComponent } from '../authentication/login-register/login-register.component';
import { AuthGuard } from '../guards/auth.guard';
import { ParamGuard } from '../guards/param-guard.guard';
import { WishlistResolver, GroupResolver } from './resolves';
import { ForgottenPasswordComponent } from '../components/forgotten-password/forgotten-password.component';
import { ForgottenPasswordResolver } from '../components/forgotten-password/ForgottenPasswordResolver';

export class WishlistRoute {
  name?: string;
  icon?: string;
  route: Route;
  children?: WishlistRoute[];
  backgroundImage?: string;
  right?: boolean;
  canActivate?: {
    canActivate: CanActivateFn;
}[]
}

@Injectable({
  providedIn: 'root'
})
export class WishlistRoutes {
  routes: WishlistRoute[] = [
    {name: 'Home', icon: 'home', route: {path: '', component: HomeComponent}},
    {
      name: 'My Wishlists', icon: 'list', route: {path: 'wishlists', canActivate: [AuthGuard]}, children: [
        {name: 'My Wishlists', icon: 'list', route: {path: '', component: MyWishlistsComponent}},
        {name: 'Add Wishlist', icon: 'plus', route: {path: 'new', component: AddWishlistComponent}},
        {route: {path: ':id', component: ViewEditWishlistComponent, canActivate: [ParamGuard], resolve: {wishlist: WishlistResolver}}}
      ]
    },
    {
      name: 'My Groups', icon: 'users', route: {path: 'groups', canActivate: [AuthGuard]}, children: [
        {name: 'My Groups', icon: 'users', route: {path: '', component: MyGroupsComponent}},
        {name: 'Add Group', icon: 'plus', route: {path: 'new', component: AddGroupComponent}},
        {route: {path: ':id', component: ViewEditGroupComponent, canActivate: [ParamGuard], resolve: {group: GroupResolver}}}
      ]
    },
    {
      route: {path: 'login', component: LoginRegisterComponent}
    },
    {
      route: {path: 'register', component: LoginRegisterComponent}
    },
    {
      route: {path: '404', component: NotFoundComponent}
    },
    // {
    //   name: 'What\'s New?', route: {path: 'whats-new', component: WhatsNewContainerComponent, canActivate: [AuthGuard]}, icon: 'commenting'
    // },
    {
      name: 'Profile', route: { path: 'profile', component: UserProfileContainerComponent, canActivate: [AuthGuard] }, icon: 'user', right: true
    },
    {
      route: { path: 'user/:id', component: ViewUserProfileContainerComponent, canActivate: [AuthGuard] }
    },
    {
      route: {
        path: 'forgottenpassword', children: [
          {
            path: ':id/:token', component: ForgottenPasswordComponent, resolve: {tokenValid: ForgottenPasswordResolver}
          }
        ]
      }
    },
    {
      route: {path: '**', redirectTo: '404'}
    }
  ];
}
