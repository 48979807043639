import { Injectable } from '@angular/core';
import {GenericHttpService} from "../../shared/services/generic-http.service";
import {Member} from "../models";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MemberService extends GenericHttpService<Member, Member> {
  _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super(httpClient,
      'members');
    this._httpClient = httpClient;
  }

  queryForGroup(term: string, groupKey: string): Observable<Member[]> {
    return this._httpClient.get<Member[]>(`${this.endpointUrl}/query/${groupKey}?term=${term}`)
  }
}
