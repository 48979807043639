import { Component, OnInit } from '@angular/core';
import {Wishlist} from "../../models";
import {ClipboardService} from "ngx-clipboard";
import {NotificationService, WishlistService} from "../../services";
import {Globals} from "../../../shared/models";

declare const UIkit:any;

@Component({
  selector: 'app-my-wishlists',
  templateUrl: './my-wishlists.component.html',
  styleUrls: ['./my-wishlists.component.scss']
})
export class MyWishlistsComponent implements OnInit {
  wishlists: Wishlist[] = [];

  constructor(private wishlistService: WishlistService,
              private clipboardService: ClipboardService,
              private notificationService: NotificationService,
              private globals: Globals) {
  }

  ngOnInit() {
    this.wishlistService.query().subscribe((wishlists) => {
      if (wishlists) {
        this.wishlists = wishlists;
      }
    });
  }

  onWishlistDeleted(wishlist) {
    this.wishlists.splice(this.wishlists.indexOf(wishlist), 1);
  }
}
