import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { EnvironmentService } from '../environments/environment.service';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { AmazonService } from './services/amazon.service';
import { UserProfileService } from './services/user-profile.service';
import { AddWishlistComponent } from './wishlists/add-wishlist/add-wishlist.component';
import { MyWishlistsComponent } from './wishlists/my-wishlists/my-wishlists.component';
import { ViewEditWishlistComponent } from './wishlists/view-edit-wishlist/view-edit-wishlist.component';
import { MyGroupsComponent } from './groups/my-groups/my-groups.component';
import { AddGroupComponent } from './groups/add-group/add-group.component';
import { ViewEditGroupComponent } from './groups/view-edit-group/view-edit-group.component';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { LoginRegisterComponent } from './authentication/login-register/login-register.component';
import { ErrorInterceptor } from './interceptors/error-interceptor';
import { JwtInterceptor } from './interceptors/jwt-interceptor';
import { SpinnerOnDisableDirective } from './spinner-on-disable.directive';
import { SpinnerDirective } from './spinner.directive';
import { ClipboardModule } from 'ngx-clipboard';
import { WishlistResolver } from './routing/resolves';
import { AutocompleteDirective } from './directives';
import { AutocompleteResultsComponent } from './directives';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './serializers/LowerCaseUrlSerializer';
import { SameAsDirective } from './validators/same-as.directive';
import { CurrencyProxyPipe } from './pipes/currency.pipe';
import { ProgressButtonComponent } from './components/progress-button/progress-button.component';
import { ClickAndLoadDirective } from './directives';
import { ForgottenPasswordComponent } from './components/forgotten-password/forgotten-password.component';
import { SharedModule } from '../shared/shared.module';
import { WishlistCardComponent } from './wishlists/wishlist-card/wishlist-card.component';
import { WishlistItemCardComponent } from './items/wishlist-item-card/wishlist-item-card.component';
import { WhatsNewContainerComponent } from './whats-new/whats-new-container/whats-new-container.component';
import { WhatsNewComponent } from './whats-new/whats-new/whats-new.component';
import { UserProfileContainerComponent } from './components/user-profile-container/user-profile-container.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { PasswordStrengthContainerComponent } from './components/password-strength-container/password-strength-container.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { ViewUserProfileComponent } from './components/view-user-profile/view-user-profile.component';
import { ViewUserProfileContainerComponent } from './components/view-user-profile-container/view-user-profile-container.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { AuthInterceptor } from 'angular-auth-oidc-client';
// import { MbSnowModule } from '@mbrewerton/mb-snow';

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        MyWishlistsComponent,
        AddWishlistComponent,
        ViewEditWishlistComponent,
        MyGroupsComponent,
        AddGroupComponent,
        ViewEditGroupComponent,
        NotFoundComponent,
        LoginRegisterComponent,
        ClickAndLoadDirective,
        SpinnerOnDisableDirective,
        SpinnerDirective,
        AutocompleteDirective,
        AutocompleteResultsComponent,
        SameAsDirective,
        CurrencyProxyPipe,
        ProgressButtonComponent,
        ForgottenPasswordComponent,
        WishlistCardComponent,
        WishlistItemCardComponent,
        WhatsNewContainerComponent,
        WhatsNewComponent,
        UserProfileContainerComponent,
        UserProfileComponent,
        PasswordStrengthContainerComponent,
        PasswordStrengthComponent,
        ViewUserProfileComponent,
        ViewUserProfileContainerComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        ClipboardModule,
        SharedModule,
        ReactiveFormsModule,
        AuthConfigModule,
        ReactiveFormsModule,
        // MbSnowModule
    ],
    providers: [
        AmazonService,
        UserProfileService,
        EnvironmentService,
        WishlistResolver,
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        },
        // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
