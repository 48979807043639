import {Directive, EventEmitter, HostListener, Input, Output} from "@angular/core";
import {Subject, Subscription} from "rxjs/index";
import {debounceTime} from "rxjs/operators/index";

  @Directive({
    selector: 'input[wlAutocomplete]',
  })
  export class AutocompleteDirective {
    modelChanged: Subject<string> = new Subject<string>();
    subscription: Subscription;
    debounce: number = 500;

    constructor() {
      this.subscription =
        this.modelChanged
          .pipe(debounceTime(this.debounce))
          .subscribe(value => {
            this.wlAutocomplete.next(value); // I need to get the data from component method passed into `wlAutocomplete`
          });
    }

    @Output() wlAutocomplete: EventEmitter<any> = new EventEmitter();

    @Input() results: any[];

    @HostListener('input', ['$event'])
    onChange($event) {
      this.modelChanged.next($event.target.value);
    }
  }
