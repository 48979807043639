import { Injectable } from '@angular/core';
import { environment} from './environment';
import {IEnvironment} from './environment.interface';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements IEnvironment {

  get production(): boolean {
    return environment.production;
  }
  get apiUrl(): string {
    return environment.apiUrl;
  }
  get authUrl(): string {
    return environment.authUrl;
  }

  get authTokenNotificationTimeInMinutes(): number {
    return environment.authTokenNotificationTimeInMinutes;
  }

  constructor() { }

}
