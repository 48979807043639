import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '../home/home.component';
import {MyWishlistsComponent} from '../wishlists/my-wishlists/my-wishlists.component';
import {MyGroupsComponent} from '../groups/my-groups/my-groups.component';
import {AddGroupComponent} from '../groups/add-group/add-group.component';
import {AddWishlistComponent} from '../wishlists/add-wishlist/add-wishlist.component';
import {ViewEditWishlistComponent} from '../wishlists/view-edit-wishlist/view-edit-wishlist.component';
import {ViewEditGroupComponent} from '../groups/view-edit-group/view-edit-group.component';
import {WishlistRoute, WishlistRoutes} from "./wishlist-route";

// const routes: WishlistRoute[] = WishlistRoutes;
/*const routes: Routes = [
  { path: '', component: HomeComponent }
];*/

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [
     RouterModule.forRoot([{ path: '', component: HomeComponent }], {})
    /*RouterModule.forRoot(routes.map((r) => {
      if (!r.children) {
        r.children = [];
      }

      r.children.forEach((child) => {
        if (!r.route.children) {
          r.route.children = [];
        }
        r.route.children.push(child.route);
      });
      return r.route;
    }))*/
  ]
})
export class AppRoutingModule {
}
