import {Directive, ElementRef, HostListener, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appLoading]'
})
export class SpinnerDirective {

  constructor(private elementRef: ElementRef) { }

  @Input('appLoading') isLoading:boolean = false;
  @HostListener('change') ngOnChanges() {
    if (this.isLoading) {
      this.elementRef.nativeElement.setAttribute('disabled', this.isLoading);
    } else {
      this.elementRef.nativeElement.removeAttribute('disabled');
    }
    console.log('isLoading', this.isLoading);
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.insertAdjacentHtml('beforeend', `<div class="uk-spinner" uk-spinner></div>`);
  }
}
