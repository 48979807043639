import { Injectable } from '@angular/core';
import { BoughtItem } from '../items/bought-item';
import {WishlistItem} from "../models";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {GenericHttpService} from "../../shared/services/generic-http.service";

@Injectable({
  providedIn: 'root'
})
export class WishlistItemService extends GenericHttpService<WishlistItem, WishlistItem> {
  _httpClient: HttpClient;
  constructor(httpClient: HttpClient) {
    super(httpClient,
      'wishlistitems',
      );
    this._httpClient = httpClient;
  }

  setBought(id: number, isBought: boolean): Observable<BoughtItem> {
    return this._httpClient.put<BoughtItem>(`${this.endpointUrl}/${id}/bought`, { isBought: isBought }, {});
  }
}
