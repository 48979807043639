import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Group, Wishlist} from "../../models";
import {NotificationService, WishlistGroupService, WishlistService} from "../../services";
import {ClipboardService} from "ngx-clipboard";
import {Globals} from "../../../shared/models";

declare const UIkit: any;

@Component({
  selector: 'wl-wishlist-card',
  templateUrl: './wishlist-card.component.html',
  styleUrls: ['./wishlist-card.component.scss']
})
export class WishlistCardComponent implements OnInit {
  @Input() wishlist: Wishlist;
  @Input() group: Group;
  @Output() wishlistRemoved: EventEmitter<Wishlist> = new EventEmitter<Wishlist>();
  @Output() wishlistUnassigned: EventEmitter<any> = new EventEmitter<any>();
  constructor(private wishlistService: WishlistService,
              private wishlistGroupService: WishlistGroupService,
              private notificationService: NotificationService,
              private clipboardService: ClipboardService,
              private globals: Globals) { }

  ngOnInit() {
  }

  unassignWishlistFromGroup() {
    const component = this;
    UIkit.modal.confirm(`Do you want to remove the wishlist <strong>${this.wishlist.name}</strong> from this group?`)
      .then(function () {
        component.wishlistGroupService.unassign(component.group.groupKey, component.wishlist.wishlistKey).subscribe(() => {
          component.wishlistUnassigned.emit(component.wishlist);
        });
      });
  }

  copyLink() {
    try {
      this.clipboardService.copyFromContent(this.globals.baseHref + '/wishlists/' + this.wishlist.wishlistKey);
      this.notificationService.success('Link copied to clipboard.');
    }
    finally {

    }
  }

  private delete(wishlist: Wishlist) {
    this.wishlistService.delete(wishlist.wishlistKey).subscribe((response) => {
      this.notificationService.success(`'${wishlist.name}' has been deleted.`);
      this.wishlistRemoved.emit();
    });
  }

  showDeletePrompt() {
    const component = this;
    UIkit.modal.confirm(`Do you want to delete <strong>${this.wishlist.name}</strong>?`).then(function() {
      component.delete(component.wishlist);
    });
  }
}
