import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'currency'
})
export class CurrencyProxyPipe implements PipeTransform {
  currencyPipe = new CurrencyPipe('en');
  transform(value,code = 'GBP',display = 'symbol',digits = '0.2',local ='en') {
    return this.currencyPipe.transform(value,code,display,digits,local)
  }

}
