import {Wishlist} from "./wishlist";
import {LinkType} from "../enums";

export class WishlistItem {
  id?: number;
  name: string;
  description: string;
  link: string;
  isBought: boolean;
  boughtBy: string;
  boughtByMe: boolean;
  price: number;
  readonly wishlistId: string;
  linkType: LinkType;

  constructor(wishlist: Wishlist, item: WishlistItem = null) {
    this.wishlistId = wishlist.id;

    // Used to create new instance for editing an item in a wishlist
    if (item != null) {
      this.id = item.id;
      this.name = item.name;
      this.description = item.description;
      this.link = item.link;
      this.price = item.price;
      this.linkType = LinkType.Unknown;
    }
  }
}
