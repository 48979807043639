import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {Router} from '@angular/router';
import { EnvironmentService } from '../../environments/environment.service';
import {User} from '../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  baseUrl: string;
  public isAuthenticated: boolean;
  public isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;

  constructor(private http: HttpClient,
              private router: Router,
              private environment: EnvironmentService,
              private oidcSecurityService: OidcSecurityService) {
    this.baseUrl = environment.authUrl;
    this.isAuthenticated$.subscribe((auth) => this.isAuthenticated = auth.isAuthenticated);
  }

  register(user: User) {
    //TODO: Work on Auth Server
    return this.http.post<any>(`${this.baseUrl}/api/account/register`, user);
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.logoffAndRevokeTokens(); // TODO: Test
  }

  getToken() {
    return this.oidcSecurityService.getAccessToken();
  }
}
