import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

export function SameAsValidator(source: string, target: string): ValidatorFn {
  return (formGroup: UntypedFormGroup): {[key: string]: boolean} | null => {
    const newPassword = formGroup.get(source).value;
    const confirmNewPassword = formGroup.get(target).value;
  console.log('2', newPassword !== confirmNewPassword ? {sameAs: true} : null);
    return newPassword !== confirmNewPassword ? {sameAs: true} : null;
  };
}
