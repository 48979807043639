import {Component, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {AuthenticationService} from "../../services/authentication-service.service";
import {ConnectableObservable, Observable, Subscription} from "rxjs";
import {finalize} from "rxjs/operators";
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from "@angular/router";
import {MailService} from "../../services/mail-service.service";
import {NotificationService} from "../../services";
import {User} from "../../../shared/models";
import { RegexUtil } from '../../utilities';
import { SameAsValidator } from '../../validators/same-as.validator';

declare var UIkit: any;

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
              private oidcSecurityService: OidcSecurityService,
              private mailService: MailService,
              private notificationService: NotificationService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  user: User = new User();
  loggingIn: boolean = false;
  errorMessage: string;
  registerErrorMessage: string;
  previewPassword: boolean;
  forgottenPasswordModal: any;
  forgottenPasswordEmail: string;
  forgottenPasswordSubscription: Subscription;
  tabs: any;
  registerForm = new UntypedFormGroup({
    password: new UntypedFormControl('', Validators.pattern(RegexUtil.password.pattern)),
    confirmPassword: new UntypedFormControl('', Validators.pattern(RegexUtil.password.pattern)),
    email: new UntypedFormControl('', [Validators.email]),
    firstName: new UntypedFormControl(''),
    lastName: new UntypedFormControl('')
  }, {
    validators: [Validators.required, SameAsValidator('password', 'confirmPassword')]
});

  login(): void {
    this.oidcSecurityService.authorize();
    // this.errorMessage = '';
    // this.loggingIn = true;
    // this.callLogin(this.user);
  }

  register(): void {
    this.loggingIn = true;
    this.authenticationService.register(this.registerForm.value)
      .pipe(finalize(() => this.loggingIn = false))
      .subscribe(() => {
        this.callLogin(this.registerForm.value);
      }, (err) => this.registerErrorMessage = err);
  }

  controlInvalid(control: any): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  togglePasswordDisplay(): void {
    this.previewPassword = !this.previewPassword;
  }

  showForgottenPasswordModal(): void {
    this.forgottenPasswordModal.show();
  }

  recoverPassword(form: any): void {
    this.notificationService.success(`Request sent. If the email address ${form.form.value.forgottenPasswordEmail} exists, you should receive an email within the next 5 minutes.`);
    this.forgottenPasswordSubscription = this.mailService.forgottenPassword(form.form.value.forgottenPasswordEmail).subscribe(() => {
      this.forgottenPasswordSubscription.unsubscribe();
      this.forgottenPasswordModal.hide();
    });
  }

  private callLogin(user: User): void {
    // this.authenticationService.login(user)
    //   .pipe(finalize(() => this.loggingIn = false))
    //   .subscribe((data) => {
    //     let redirect = decodeURIComponent(this.activatedRoute.snapshot.queryParams.redirect);
    //     this.router.navigateByUrl(redirect != "undefined" && redirect != "null" ? redirect : '/');
    //   }, (err) => {
    //     this.errorMessage = err.errorDescription;
    //   });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.forgottenPasswordModal = UIkit.modal('#forgotten-password-modal');
    let fragment = this.activatedRoute.snapshot.fragment;
    if (fragment && fragment.indexOf('register') > -1) {
      this.tabs = UIkit.tab('#tabs').show(1);
    }
  }
}
