import { Component, Input, OnInit } from '@angular/core';
import { NewItemArticle } from '../../models';

@Component({
  selector: 'wl-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.css']
})
export class WhatsNewComponent implements OnInit {
  @Input() newUpdates: NewItemArticle[];

  constructor() { }

  ngOnInit(): void {
  }

}
