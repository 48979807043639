import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericHttpService} from '../../shared/services/generic-http.service';
import {ForgottenPasswordViewModel} from '../components/forgotten-password/forgotten-password-view.model';
 import { ChangePasswordModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends GenericHttpService<any, any> {
  private _httpClient: HttpClient;
  private _endpoint =  this.authUrl;

  constructor(httpClient: HttpClient) {
    super(httpClient,
      'account');
    this._httpClient = httpClient;
  }

  validateForgottenPasswordToken(id: string, token: string) {
    return this._httpClient.get<boolean>(`${this._endpoint}/ValidateForgottenPasswordToken`, { params: { id: id, token: token }});
  }

  resetPassword(forgottenPasswordViewModel: ForgottenPasswordViewModel) {
    return this._httpClient.post(`${this._endpoint}/ResetPassword`, forgottenPasswordViewModel);
  }

  changePassword(changePasswordModel: ChangePasswordModel) {
    return this._httpClient.put(`${this._endpoint}/pwd`, changePasswordModel);
  }
}
