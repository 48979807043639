import {Injectable} from '@angular/core';
import {About} from "../models";
import {HttpClient} from "@angular/common/http";
import {GenericHttpService} from "../../shared/services/generic-http.service";

@Injectable({
  providedIn: 'root'
})
export class AboutService extends GenericHttpService<About, About> {
  private _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    super(httpClient,
      'about');
    this._httpClient = httpClient;
  }
}
