import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Group, Wishlist, WishlistItem} from "../../models";
import {GroupService, NotificationService, WishlistItemService, WishlistService} from "../../services";
import {LinkType, ShareType} from "../../enums";
import {ClipboardService} from "ngx-clipboard";
import {Globals} from "../../../shared/models";
import { AmazonService } from '../../services/amazon.service';

declare var UIkit: any;

@Component({
  selector: 'app-view-edit-wishlist',
  templateUrl: './view-edit-wishlist.component.html',
  styleUrls: ['./view-edit-wishlist.component.scss']
})
export class ViewEditWishlistComponent implements OnInit {
  wishlist: Wishlist;
  wishlistItem: WishlistItem;
  editingWishlistItem: WishlistItem;
  addItemModal: any;
  shareModal: any;
  shareType: typeof ShareType = ShareType;
  groups: Group[];
  selectedGroupKey: string;
  deletePrompt: any;
  linkType: typeof LinkType = LinkType;
  globals: Globals;

  constructor(private route: ActivatedRoute,
              private amazonService: AmazonService,
              private router: Router,
              private wishlistItemService: WishlistItemService,
              private wishlistService: WishlistService,
              private groupService: GroupService,
              private notificationService: NotificationService,
              private clipboardService: ClipboardService) {
  }

  share(groupKey: string): void {
      this.wishlistService.share(this.wishlist.wishlistKey, groupKey).subscribe((resp) => {
        this.notificationService.success('Wishlist has been shared.');
        this.shareModal.hide();
      });
  }

  showShareDialog(): void {
    this.shareModal.show();
  }

  showAddEditItemDialog(item?: WishlistItem): void {
    this.wishlistItem = new WishlistItem(this.wishlist, item);

    this.addItemModal.show();
  }

  showDeletePrompt(item: WishlistItem): void {
    let component = this;
    UIkit.modal.confirm(`Do you want to delete <strong>${item.name}</strong>?`).then(function() {
      component.wishlistItemService.delete(item.id).subscribe(() => {
        let index = component.wishlist.wishlistItems.findIndex((i) => {
          return i.id == item.id;
        });
        component.wishlist.wishlistItems.splice(index, 1);
      });
    });
  }

  addItem(): void {
    this.wishlistItemService.create(this.wishlistItem).subscribe((item) => {
      this.wishlist.wishlistItems.push(item);
      this.addItemModal.hide();
    });
  }

  saveItem(): void {
    this.wishlistItemService.put(this.wishlistItem).subscribe((item: WishlistItem) => {
      let index = this.wishlist.wishlistItems.findIndex(x => x.id == item.id);
      this.wishlist.wishlistItems.splice(index, 1, item);
      this.addItemModal.hide();
    });
  }

  ngOnInit() {
    this.wishlist = this.route.snapshot.data.wishlist;
    this.wishlistItem = new WishlistItem(this.wishlist);

    this.groupService.query().subscribe((groups) => {
      this.groups = groups;
      if (this.groups && this.groups.length) {
        this.selectedGroupKey = this.groups[0].groupKey;
      }
    });
  }

  ngAfterViewInit(){
    this.addItemModal = UIkit.modal('#add-item-modal');
    this.shareModal = UIkit.modal('#share-modal');
    // this.deletePrompt = UIkit.confirm('#share-modal');
  }

  checkLinkType(link: string) {
    if (link) {
      let loweredLink = link.toLowerCase();
      if (loweredLink.search(/amazon/g) > -1) {
        this.wishlistItem.linkType = LinkType.Amazon;
      } else {
        this.wishlistItem.linkType = LinkType.Unknown;
      }
    }
  }

  createAffiliateLink() {
    const sub = this.amazonService.affiliate(this.wishlistItem.link)
      .subscribe((affiliateLink) => {
        this.wishlistItem.link = affiliateLink.url;
        sub.unsubscribe();
      })
  }

  copyLink() {
    try {
      this.clipboardService.copyFromContent(window.location.href);
      this.notificationService.success('Link copied to clipboard.');
    }
    finally {

    }
  }

  formatPrice(price: number) {
    this.wishlistItem.price = Number(price.toFixed(2));
  }
}
