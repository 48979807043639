import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ChangePasswordModel, UserProfileModel } from '../../models';
import { NotificationService } from '../../services';
import { AccountService } from '../../services/account-service.service';
import { UserProfileService } from '../../services/user-profile.service';
import { RegexUtil } from '../../utilities';
import { SameAsValidator } from '../../validators/same-as.validator';

@Component({
  selector: 'wl-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit, OnChanges {
  @Input() userProfile: UserProfileModel;
  form = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required]),
    lastName: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    bio: new UntypedFormControl(''),
  });
  saving: boolean;
  passwordForm = new UntypedFormGroup({
    currentPassword: new UntypedFormControl('', [Validators.required]),
    newPassword: new UntypedFormControl('', [Validators.required, Validators.pattern(RegexUtil.password.pattern)]),
    confirmNewPassword: new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(RegexUtil.password.pattern)]))
  }, [SameAsValidator('newPassword', 'confirmNewPassword')]);
  changingPassword: boolean;

  constructor(private userProfileService: UserProfileService,
              private accountService: AccountService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes && !!changes['userProfile'] && this.userProfile != null) {
      this.form.patchValue(this.userProfile);
    }
  }

  controlInvalid(key: string): boolean {
    const control = this.form.get(key);
    return control.invalid && (control.dirty || control.touched);
  }

  updateProfile() {
    this.saving = true;
    const sub = this.userProfileService.put(this.form.value)
      .pipe(finalize(() => this.saving = false))
      .subscribe((result) => sub.unsubscribe());
  }

  changePassword() {
    this.changingPassword = true;
    const sub = this.accountService.changePassword(this.passwordForm.value)
      .pipe(finalize(() => this.changingPassword = false))
      .pipe(catchError((err) => {
        this.notificationService.error(err);
        return throwError(err);
      }))
      .subscribe((result) => {
        this.notificationService.success(`Your password has been changed.`);
        this.passwordForm.reset();
        this.passwordForm.markAsPristine();
        sub.unsubscribe();
      });
  }
}
