import { ChangeDetectorRef, Component } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { filter, map } from 'rxjs/operators';
import { WishlistRoutes } from './routing/wishlist-route';
import { AuthenticationService } from './services';

declare var $: any;
declare var UIkit: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  backgroundImage: string;
  private helpModal: any;

  constructor(public authService: AuthenticationService,
    public oidcSecurityService: OidcSecurityService,
    private oidcEventService: PublicEventsService,
    public router: Router,
    private wishlistRoutes: WishlistRoutes,
    private changeDetector: ChangeDetectorRef) {
    const config = router.config;
    wishlistRoutes.routes.forEach((r) => {
      // We don't want to re-add the `Home` route.
      if (r.name != 'Home') {
        if (!r.children) {
          r.children = [];
        }
        r.children.forEach((child) => {
          if (!r.route.children) {
            r.route.children = [];
          }
          r.route.children.push(child.route);
        });
        config.push(r.route);
      }
    });
    router.resetConfig(config);
  }

  ngOnInit() {
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
    });

    this.oidcEventService.registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.SilentRenewStarted))
      .subscribe((event) => console.log('[SilentRenewStarted] :: ', event))

    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        map((e => {
          let path = this.router.url.substr(1);
          var routeMatch = this.wishlistRoutes.routes.find(r => r.route.path !== '' && path.indexOf(r.route.path) > -1);
          this.backgroundImage = routeMatch ? routeMatch.backgroundImage : '';
        }
        ))
      )
  }

  ngAfterViewInit() {
    this.helpModal = UIkit.modal($('#help-modal'));
    // Tell the changeDetector to re-check the component. This could be expensive, consider alternative
    this.changeDetector.detectChanges();
  }

  showHelpModal() {
    this.helpModal.show();
  }
}

