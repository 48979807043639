import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'wl-autocomplete-results',
  templateUrl: './autocomplete-results.component.html',
  styleUrls: ['./autocomplete-results.component.scss']
})
export class AutocompleteResultsComponent implements OnInit {
  _displayProperty: string;

  constructor() { }

  @Input() results: any[] = [];

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() displayProperty: string;

  @Input() icon: string;

  ngOnInit() {

  }
}
