import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wl-password-strength-container',
  templateUrl: './password-strength-container.component.html',
  styleUrls: ['./password-strength-container.component.css']
})
export class PasswordStrengthContainerComponent implements OnInit {
  @Input() passwordField: string;

  constructor() { }

  ngOnInit(): void {
  }

}
