import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfileModel } from '../../models';
import { UserProfileService } from '../../services/user-profile.service';

@Component({
  selector: 'wl-user-profile-container',
  templateUrl: './user-profile-container.component.html',
  styleUrls: ['./user-profile-container.component.css']
})
export class UserProfileContainerComponent implements OnInit {
  userProfile$: Observable<UserProfileModel>;

  constructor(private userProfileService: UserProfileService) { }

  ngOnInit(): void {
    this.userProfile$ = this.userProfileService.get();
  }

}
