import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericHttpService } from '../../shared/services/generic-http.service';

@Injectable()
export class AmazonService extends GenericHttpService<string, string> {
  private _httpClient: HttpClient;
  constructor(httpClient: HttpClient) {
    super(httpClient, 'amazon');
    this._httpClient = httpClient;
  }

  affiliate(link: string): Observable<{ url: string}> {
    return this._httpClient.get<{ url: string}>(`${this.endpointUrl}/affiliate?link=${encodeURI(link)}`);
}
}
