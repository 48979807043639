import { Injectable } from '@angular/core';
import {GenericHttpService} from "../../shared/services/generic-http.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MailService extends GenericHttpService<any, any> {
  _httpClient: HttpClient;
  constructor(httpClient: HttpClient) {
    super(httpClient, 'mail');
    this._httpClient = httpClient;
  }

  forgottenPassword(email: string) {
    return this._httpClient.post(`${this.endpointUrl}/ForgottenPassword`, { email: email });
  }
}
