import { Component, OnInit } from '@angular/core';
import {PublicityLevel, Group} from "../../models";
import {GroupService} from "../../services";
import {Router} from "@angular/router";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {

  group: Group = new Group();
  creatingGroup: boolean;

  constructor(private groupService: GroupService, private router: Router) {
  }

  createGroup(): void {
    this.creatingGroup = true;
    this.groupService.create(this.group)
      .pipe(finalize(() => {
        this.creatingGroup = false;
      }))
      .subscribe((resp) => {
        this.router.navigateByUrl('/groups/' + resp.groupKey);
      });
  }

  ngOnInit() {
  }

}
