import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {Group} from "../../models";
import {GroupService} from "../../services";

@Injectable({
  providedIn: 'root'
})
export class GroupResolver {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Group> | Promise<Group> | Group {
    return this.groupService.get(route.paramMap.get('id'));
  }

  constructor(private groupService: GroupService) { }
}
