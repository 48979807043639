import {Injectable} from "@angular/core";
import { EnvironmentService } from '../../environments/environment.service';
import {User} from "./user";

@Injectable()
export class Globals {
  baseHref: string = `${window.location.protocol}//${window.location.host}`;
  auth: object;
  currentUser: User;
}
