import {Injectable} from '@angular/core';

declare var UIkit: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor() {
  }

  public success(message): void {
    UIkit.notification(message, {
        status: 'success'
      }
    )
  }

  public error(message): void {
    UIkit.notification(message, {
      status: 'error'
    })
  }
}
