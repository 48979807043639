import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Wishlist, WishlistItem } from '../../models';
import { WishlistItemService } from '../../services';

@Component({
  selector: 'wl-wishlist-item-card',
  templateUrl: './wishlist-item-card.component.html',
  styleUrls: ['./wishlist-item-card.component.css']
})
export class WishlistItemCardComponent implements OnInit {
  @Input() item: WishlistItem;
  @Input() wishlist: Wishlist;
  @Output() showAddEditDialog = new EventEmitter();
  @Output() showDeletePrompt = new EventEmitter();
  showFullLink: boolean = false;

  constructor(private wishlistItemService: WishlistItemService) {
  }

  ngOnInit(): void {
  }

  setIsBought($event, item: WishlistItem): void {
    item.boughtByMe = item.boughtByMe || true;
    console.log($event);
    const subscription = this.wishlistItemService.setBought(item.id, $event.target.checked)
      .subscribe((boughtItem) => {
        item.boughtBy = boughtItem.isBought ? boughtItem.boughtBy : null;
        item.isBought = boughtItem.isBought;
        subscription.unsubscribe();
      });
  }

  callShowAddEditItemDialog(item: WishlistItem) {
    this.showAddEditDialog.emit(item);
  }

  callShowDeletePrompt(item: WishlistItem) {
    this.showDeletePrompt.emit(item);
  }

  toggleShowFullLink() {
    this.showFullLink = !this.showFullLink;
  }
}
