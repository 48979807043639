import {PublicityLevel, WishlistItem} from "./"

export class Wishlist {
  id: string;
  name: string;
  description: string;
  wishlistKey: string;
  publicityLevel: PublicityLevel;
  wishlistItems: WishlistItem[];
  wishlistItemCount: number;
  ownerName: string;
  isMine: boolean;
}
