import { Component } from '@angular/core';
import { Route } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {WishlistRoute, WishlistRoutes} from "../routing/wishlist-route";
import {AuthenticationService} from '../services';
import {AboutService} from "../services/about-service.service";
import {About} from "../models";

declare var $: any;
declare var UIkit: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  routes: WishlistRoute[];
  modals: any = {};
  aboutModel: About;
  rightRoutes: WishlistRoute[];

  constructor(private oidcSecurityService: OidcSecurityService,
              wishlistRoutes: WishlistRoutes,
              private aboutService: AboutService,
              public authenticationService: AuthenticationService){
    this.routes = wishlistRoutes.routes.filter((root) => {
      if (!root.name) return false;

      if (root.children) {
        root.children = root.children.filter((child) => { return !!child.name;})
      }

      return true;
    });

    this.rightRoutes = this.routes.filter((r) => !!r.right);
  }

  logout(): void {
    this.authenticationService.logout();
  }

  login() {
    this.authenticationService.login();
  }

  showAboutModal(): void {
    this.modals.about.show();
  }

  showBugModal(): void {
    this.modals.bug.show();
  }

  ngOnInit(){
    this.aboutService.get().subscribe((value: About) => {
      this.aboutModel = value;
    });
  }

  // Sets up the auto-closing of the navigation dropdowns
  ngAfterViewInit(){
    this.modals.bug = UIkit.modal($('#bug-modal'));
    this.modals.about = UIkit.modal($('#about-modal'));

    $('.uk-dropdown-close').on('click', function() {
      window.setTimeout(() => {
        $('.uk-navbar-dropdown.uk-open').hide();
      },0);
    });
  }

  isAuthenticatedRoute(route: Route): boolean {
    return !route.canActivate || !!route.canActivate;
  }
}
